<template>
  <div>
    <b-table-simple caption-top responsive bordered>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">STT</b-th>
          <b-th class="text-center">Thumbnail</b-th>
          <b-th class="text-center">Type</b-th>
          <b-th class="text-center">Amount</b-th>
          <b-th class="text-center">Price</b-th>
          <b-th class="text-center">Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item, index) in items">
          <b-tr :key="item._id || index">
            <b-th class="text-center">{{ index + 1 }}</b-th>
            <b-th class="text-center">
              <b-card-img v-if="item.thumbnail" class="item-thumbnail" :src="item.thumbnail" alt="item-thumbnail" />
            </b-th>
            <b-th class="text-center"> {{ convertProductType(item.product_type) }}</b-th>
            <b-th class="text-center"> {{ item.amount }}</b-th>
            <b-th class="text-center"> {{ item.price }}</b-th>
            <b-th class="text-center">
              <div class="d-flex justify-content-center">
                <b-button
                variant="gradient-warning"
                style="margin-right: 5px"
                class="btn-icon"
                @click="editItem(item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                @click="confirmDelete(item.id)"
                variant="gradient-danger"
                class="btn-icon"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              </div>
            </b-th>
          </b-tr>
          
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import service from "../../service";
export default {
  props: {
    items: { type: Array, required: true },
  },
  data(){
    return{
    
    }
  },
  methods: {
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteItem(id);
          }
        });
    },
    async deleteItem(id) {
      await service.delete({ id: id });
      this.$emit("getList");
    },
    editItem(id) {
      this.$router.push({
        name: "earn-product-detail",
        params: { product_id: id },
      });
    },
    convertProductType(type){
      if (type == 1){
        return "Gem"
      }
      return "Unknown"
    }
  },
};
</script>
<style scoped>
  .item-thumbnail{
    max-width: 50px;
  }
  .table th{
    padding: 0.72rem 1rem;
  }
</style>
