<template>
  <div>
    <b-button variant="primary" @click="isModalShow=true">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else icon="PlusIcon" class="mr-50" />
    <span class="align-middle">Add New</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new item"
      hide-footer
      size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col cols="3">
              <strong class="h6">Thumbnail</strong>
              <ui-component :component-data="product_thumbnail" class="border" />
            </b-col>
            <b-col cols="9">
              <validation-provider
                name="Type"
                v-slot="validationContext"
                :rules="{ required: true}"
              >
                <b-form-group
                  label="Type"
                  label-for="h-type"
                >
                  <b-form-select 
                    v-model="product.product_type"
                    :options="type_options"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-type-feedback" 
                  />
                  <b-form-invalid-feedback id="input-type-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Amount"
                v-slot="validationContext"
                :rules="{ required: true}"
              >
                <b-form-group
                  label="Amount"
                  label-for="h-amount"
                >
                  <b-form-input 
                    v-model="product.amount"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-amount-feedback" 
                  />
                  <b-form-invalid-feedback id="input-amount-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Price"
                v-slot="validationContext"
                :rules="{ required: true}"
              >
                <b-form-group
                  label="Price"
                  label-for="h-price"
                >
                  <b-form-input 
                    v-model="product.price"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-price-feedback" 
                  />
                  <b-form-invalid-feedback id="input-price-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
      <hr>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import VueSelect from 'vue-select'
import defaultObject from "../../defaultObject"
export default {
  components:{
    ValidationProvider,
    ValidationObserver,
    VueSelect
  },
  data(){
    return{
      isLoading: false,
      isModalShow: false,
      product: JSON.parse(JSON.stringify(defaultObject)),
      product_thumbnail: {
        type: "image",
        value: null,
        meta: {
          alt: null,
          redirect_link: null
        }
      },
      type_options: [
        {text: "Gem", value: 1}
      ],
    }
  },
  watch:{
    "product_thumbnail.value": function (newVal){
      this.product.thumbnail = newVal
    } 
  },
  methods: {
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true;
        let new_item = await service.create(JSON.stringify(this.product));
        this.isLoading = false;
        if (new_item) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Product created!' })
          this.$emit("create");
          this.cancel();
        }
      }
    },
    cancel(){
      this.isModalShow = false;
      this.product = JSON.parse(JSON.stringify(defaultObject));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>